<template>
    <div style="width: 100%;height: 100%;">
        <div id="container"></div>
        <div class="input-card">
            <div >
                <h4 style="font-weight:bold;text-align: center">详情明细</h4>
                <div class="myContentList">
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">司机:</div>
                                <div style="float: left">{{nodeDetails.driverName}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">车牌:</div>
                                <div style="float: left">{{nodeDetails.truckNo}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">司机电话:</div>
                                <div style="float: left">{{nodeDetails.driverMobile}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">货物信息:</div>
                                <div style="float: left"><el-tag size="mini">{{nodeDetails.tioQty}}件</el-tag> <el-tag size="mini">{{nodeDetails.tioWeight}}KG</el-tag> <el-tag size="mini">{{nodeDetails.tioVolume}}m³</el-tag></div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">发车时间:</div>
                                <div style="float: left">{{nodeDetails.sendStratTime}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">发车地点:</div>
                                <div style="float: left">{{nodeDetails.sendStratAddress}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">终止时间:</div>
                                <div style="float: left">{{nodeDetails.sendEndTime}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="grid-content bg-purple-dark">
                                <div style="font-weight: bold;float: left">终止地点:</div>
                                <div style="float: left">{{nodeDetails.sendEndAddress}}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <h4 style="font-weight:bold;text-align: center">播放速度</h4>
            <el-slider
                    v-model="value1"
                    :step="20"
                    show-stops
                    :format-tooltip="formatTooltip"
            >
            </el-slider>
            <h4 style="font-weight:bold;text-align: center">轨迹回放控制</h4>
            <div class="input-item">
                <input type="button" class="btn" value="开始动画" id="start" @click="startAnimation()" />
                <input type="button" class="btn" value="暂停动画" id="pause" @click="pauseAnimation()" />
            </div>
            <div class="input-item">
                <input type="button" class="btn" value="继续动画" id="resume" @click="resumeAnimation()" />
                <input type="button" class="btn" value="停止动画" id="stop" @click="stopAnimation()" />
            </div>
        </div>
    </div>
</template>
<style>
    .info-title {
        color: white;
        font-size: 14px;
        background-color: #25A5F7;
        line-height: 26px;
        padding: 0px 0 0 6px;
        font-weight: lighter;
        letter-spacing: 1px
    }

    .info-content {
        font: 12px Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial;
        padding: 10px;
        color: #666666;
        line-height: 23px;
    }

    .info-content img {
        float: left;
        margin: 3px;
    }
    .amap-info-content{
        padding: 0;
    }
</style>
<script>
    import axios from 'axios';
    import imgicon from '../../assets/dir-via-marker_6.png';
    export default {
        mounted() {
            this.ampStateTrajectory();
            setTimeout(() => {
                this.initMap();
            }, 3000);
        },
        beforeDestroy() {
            this.map && this.map.destroy();
        },
        data() {
            return {
                map: null,
                mapCenter:[116.397428, 39.90923],
                marker: null,
                makerCenter:[116.397428, 39.90923],
                lineArr: [],
                startPoint:[],
                endPoint:[],
                nodeDetails:{
                    driverName:'',
                    truckNo:'',
                    driverMobile:'',
                    sendStratTime:'',
                    sendStratAddress:'',
                    sendEndTime:'',
                    sendEndAddress:'',
                    tioQty:'',
                    tioWeight:'',
                    tioVolume:''
                },
                value1: 40,
                aMapSpeed:500,
                arrivePointVos: []
            };
        },
        methods: {
            formatTooltip(val) {
                if(val / 10 == 0){
                    return '初始'
                } else if(val / 10 == 2){
                    this.aMapSpeed = 200;
                    return '0.75倍';
                }else if(val / 10 == 4){
                    this.aMapSpeed = 500;
                    return '1倍';
                }else if(val / 10 == 6){
                    this.aMapSpeed = 1000;
                    return '1.5倍';
                }else if(val / 10 == 8){
                    this.aMapSpeed = 1500;
                    return '2倍';
                }else if(val / 10 == 10){
                    this.aMapSpeed = 2000;
                    return '2.5倍';
                }
            },
            ampStateTrajectory(){
                if(this.$route.query.insertState == 'A'){
                    this.getAmapList('A',this.$route.query.orderNo);
                    //订单轨迹
                    axios.get(`${this.$apiPath}/api/urban/track/trace/searchTraceByOrderNo?orderNo=`+ this.$route.query.orderNo).then(res=>{
                        if(res.data.success == true){
                            const pointVos = res.data.data.pointVos;
                            this.makerCenter = pointVos[0].loc;
                            this.startPoint = res.data.data.startPoint.loc;
                            this.endPoint = res.data.data.endPoint.loc;
                            const mapList = [];
                            for(var i = 0;i<pointVos.length;i++){
                                const aa = pointVos[i].loc;
                                mapList.push(aa)
                            }
                            let aaa =JSON.stringify(mapList)
                            this.lineArr = JSON.parse(aaa);
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    })
                }else if(this.$route.query.insertState == 'B'){
                    this.getAmapList('B',this.$route.query.batchNo);
                    //批次轨迹
                    axios.get(`${this.$apiPath}/api/urban/track/trace/searchTraceByBatchNo?batchNo=`+ this.$route.query.batchNo).then(res=>{
                        if(res.data.success == true){
                            const pointVos = res.data.data.pointVos;
                            this.makerCenter = pointVos[0].loc;
                            this.startPoint = res.data.data.startPoint.loc;
                            this.endPoint = res.data.data.endPoint.loc;
                            const mapList = [];
                            for(var i = 0;i<pointVos.length;i++){
                                const aa = pointVos[i].loc;
                                mapList.push(aa)
                            }
                            let aaa =JSON.stringify(mapList)
                            this.lineArr = JSON.parse(aaa);
                            this.arrivePointVos = res.data.data.arrivePointVos;
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    })
                }else if(this.$route.query.insertState == 'C'){
                    this.getAmapList('C',this.$route.query.truckNo);
                    //车辆轨迹
                    axios.get(`${this.$apiPath}/api/urban/track/trace/searchTraceByTruckNo?truckNo=`+ this.$route.query.truckNo).then(res=>{
                        if(res.data.success == true){
                            const pointVos = res.data.data.pointVos;
                            this.makerCenter = pointVos[0].loc;
                            this.startPoint = res.data.data.startPoint.loc;
                            this.endPoint = res.data.data.endPoint.loc;
                            const mapList = [];
                            for(var i = 0;i<pointVos.length;i++){
                                const aa = pointVos[i].loc;
                                mapList.push(aa)
                            }
                            let aaa =JSON.stringify(mapList)
                            this.lineArr = JSON.parse(aaa);
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    })
                }
            },
            initMap() {
                this.map = new AMap.Map("container", {
                    resizeEnable: true,
                    center: this.mapCenter,
                    zoom: 17
                });

                this.marker = new AMap.Marker({
                    map: this.map,
                    position: this.makerCenter,
                    icon: "https://webapi.amap.com/images/car.png",
                    offset: new AMap.Pixel(-26, -15),
                    autoRotation: true,
                    angle: -90
                });

                // 绘制轨迹
                let polyline = new AMap.Polyline({
                    map: this.map,
                    path: this.lineArr,
                    showDir: true,
                    strokeColor: "#28F", //线颜色
                    // strokeOpacity: 1,     //线透明度
                    strokeWeight: 6 //线宽
                    // strokeStyle: "solid"  //线样式
                });

                let passedPolyline = new AMap.Polyline({
                    map: this.map,
                    // path: this.lineArr,
                    strokeColor: "#AF5", //线颜色
                    // strokeOpacity: 1,     //线透明度
                    strokeWeight: 6 //线宽
                    // strokeStyle: "solid"  //线样式
                });
                // 创建一个 Icon
                var startIcon = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(30, 39),
                    // 图标的取图地址
                    image: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                    // 图标所用图片大小
                    imageSize: new AMap.Size(135, 40),
                    // 图标取图偏移量
                    imageOffset: new AMap.Pixel(-9, -3)
                });

                // 将 icon 传入 marker
                var startMarker = new AMap.Marker({
                    position: new AMap.LngLat(this.startPoint[0],this.startPoint[1]),
                    icon: startIcon,
                    offset: new AMap.Pixel(-13, -30)
                });
                // 创建一个 icon
                var endIcon = new AMap.Icon({
                    size: new AMap.Size(30, 39),
                    image: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                    imageSize: new AMap.Size(135, 40),
                    imageOffset: new AMap.Pixel(-95, -3)
                });

                // 将 icon 传入 marker
                var endMarker = new AMap.Marker({
                    position: new AMap.LngLat(this.endPoint[0],this.endPoint[1]),
                    icon: endIcon,
                    offset: new AMap.Pixel(-13, -30)
                });

                this.marker.on("moving", function (e) {
                    passedPolyline.setPath(e.passedPath);
                });
                let result = this.arrivePointVos;
                var markers = [];
                let me = this;
                for (var i = 0; i < result.length; i++) {
                    var point=[result[i].loc[0],result[i].loc[1]];
                    var arriveWaybillVos = result[i].arriveWaybillVos;
                    let imgIconUrl;
                    for(var j = 0; j < arriveWaybillVos.length; j++){
                        if(arriveWaybillVos.length > 1){
                            imgIconUrl = imgicon;
                        }else {
                            imgIconUrl = 'https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png'
                        }
                    }
                    // 创建一个 Icon
                    var passingPointMarkerIcon = new AMap.Icon({
                        // 图标尺寸
                        size: new AMap.Size(30, 39),
                        // 图标的取图地址
                        image: imgIconUrl,
                        // 图标所用图片大小
                        imageSize: new AMap.Size(30, 39),
                        // 图标取图偏移量
                        imageOffset: new AMap.Pixel(0, 0)
                    });
                    var markersList = new AMap.Marker({
                        position: point,
                        map: this.map,
                        arriveWaybillVos: result[i].arriveWaybillVos,
                        //address: result[i].sendEndAddress,
                        //cargoInformation:result[i].qwv.split('/'),
                        //locatetime:result[i].locatetime,
                        icon: passingPointMarkerIcon,
                        visible: true,
                    });
                    AMap.event.addListener(markersList, 'click', function (e) {
                        var clouddata = e.target.w;
                        var clouddataLst = clouddata.arriveWaybillVos;
                        var _location = [clouddata.position.lng, clouddata.position.lat];
                        let contentHtml = '';
                        for(var a = 0; a < clouddataLst.length; a++){
                            let cargoInformation =  clouddataLst[a].qwv.split('/');
                            contentHtml += '<div class="info-title">车辆到达点信息'+(a+1)+'</div><div class="info-content">'
                                    +'运单号：'+clouddataLst[a].orderNo+'<br/>'
                                    +'收货方名称：'+clouddataLst[a].vendeeName+'<br/>'
                                    +'送达时间：'+me.$moment(clouddataLst[a].locatetime).format("YYYY-MM-DD HH:mm:ss")+'<br/>'
                                    +'货物信息：'+cargoInformation[0]+'件'+'&nbsp;&nbsp;'+cargoInformation[1]+'KG'+'&nbsp;&nbsp;'+cargoInformation[2]+'M³'+'<br/>'
                                    +'送达地址：'+clouddataLst[a].sendEndAddress+'<br/>'
                                +'</div>'
                        }
                        var infoWindow = new AMap.InfoWindow({
                            content:contentHtml,
                            size: new AMap.Size(0, 0),
                            autoMove: true,
                            offset: new AMap.Pixel(0, -25)
                        });
                        infoWindow.open(me.map, _location);
                    });
                    markers.push(markersList);
                }
                // 将 markers 添加到地图
                var that = this;
                setTimeout(function () {
                    that.map.add([startMarker, endMarker,markers]);
                }, 1000);
                this.map.setFitView();
            },
            startAnimation() {
                this.marker.moveAlong(this.lineArr, this.aMapSpeed);
            },
            pauseAnimation() {
                this.marker.pauseMove();
            },
            resumeAnimation() {
                this.marker.resumeMove();
            },
            stopAnimation() {
                this.marker.stopMove();
            },
            getAmapList(stats,getData){
                if(stats == 'A'){
                    const data = new URLSearchParams();
                    data.append('orderNo', getData);
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/tioNode/getNodeByOrderNo`,
                        data:data
                    }).then(res=> {
                        if(res.data.tioVolume == null || res.data.tioVolume == 'null'){
                            res.data.tioVolume = '0'
                        }
                        if(res.data.tioWeight == null || res.data.tioWeight == 'null'){
                            res.data.tioWeight = '0'
                        }
                        if(res.data.tioQty == null || res.data.tioQty == 'null'){
                            res.data.tioQty = '0'
                        }
                        res.data.sendStratTime = this.$moment(res.data.sendStratTime).format("YYYY-MM-DD HH:mm:ss");
                        res.data.sendEndTime = this.$moment(res.data.sendEndTime).format("YYYY-MM-DD HH:mm:ss");

                        this.nodeDetails = res.data;
                    });
                } else if(stats == 'B'){
                    const data = new URLSearchParams();
                    data.append('batchNo', getData);
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/tioNode/getNodeByBatchNo`,
                        data:data
                    }).then(res=> {
                        if(res.data.tioVolume == null || res.data.tioVolume == 'null'){
                            res.data.tioVolume = '0'
                        }
                        if(res.data.tioWeight == null || res.data.tioWeight == 'null'){
                            res.data.tioWeight = '0'
                        }
                        if(res.data.tioQty == null || res.data.tioQty == 'null'){
                            res.data.tioQty = '0'
                        }
                        res.data.sendStratTime = this.$moment(new Date(parseInt(res.data.sendStratTime))).format("YYYY-MM-DD HH:mm:ss");
                        res.data.sendEndTime = this.$moment(new Date(parseInt(res.data.sendEndTime))).format("YYYY-MM-DD HH:mm:ss");
                        this.nodeDetails = res.data;
                    });
                } else if(stats == 'C'){
                    const data = new URLSearchParams();
                    data.append('truckNo', getData);
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/tioNode/getNodeByBatchNo`,
                        data:data
                    }).then(res=> {
                        if(res.data.tioVolume == null || res.data.tioVolume == 'null'){
                            res.data.tioVolume = '0'
                        }
                        if(res.data.tioWeight == null || res.data.tioWeight == 'null'){
                            res.data.tioWeight = '0'
                        }
                        if(res.data.tioQty == null || res.data.tioQty == 'null'){
                            res.data.tioQty = '0'
                        }
                        res.data.sendStratTime = this.$moment(res.data.sendStratTime).format("YYYY-MM-DD HH:mm:ss");
                        res.data.sendEndTime = this.$moment(res.data.sendEndTime).format("YYYY-MM-DD HH:mm:ss");
                        this.nodeDetails = res.data;
                    });
                }
            },
        }
    };
</script>
<style>
    .amap-info-contentContainer{
        max-height:400px;
        overflow:auto;
    }
</style>
<style  scoped>
    @import url('https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css');

    #container {
        height: 100%;
        width: 100%;
    }

    .input-card .btn {
        margin-right: 1.2rem;
        width: 9rem;
    }

    .input-card .btn:last-child {
        margin-right: 0;
    }
    .myContentList .el-row{
        margin-bottom: 5px;
        font-size: 12px;
    }
    .playCard{
        background-color: #fff;
        background-clip: border-box;
        border-radius: .25rem;
        width: 50rem;
        height: 5rem;
        border-width: 0;
        border-radius: 0.4rem;
        box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
        position: fixed;
        bottom: 1.5rem;
        left: 1rem;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0.75rem 1.25rem;
    }
    .grid-content{
        font-size: 14px;
    }
</style>